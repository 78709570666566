import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Button, Col, FormGroup } from "reactstrap";
import { getLogger } from "../../utilities/LogConfig";
import { IAddress } from "../../models";
import AddressEditor from "../AddressEditor";

const logger = getLogger("ConfirmAddress");

interface IOwnProps {
  name: string;
  address: IAddress;
  changeAddress(address: IAddress): void;
  confirmAddress(): void;
}

interface IConfirmAddressState {
  showChangeAddress: boolean;
}

export class ConfirmAddress extends React.Component<
  IOwnProps & RouteComponentProps<any>,
  IConfirmAddressState
> {
  public state = {
    showChangeAddress: false
  };

  constructor(props: IOwnProps & RouteComponentProps<any>, context: any) {
    super(props, context);
    this.confirmShipAddress = this.confirmShipAddress.bind(this);
    this.changeShipAddress = this.changeShipAddress.bind(this);
    this.saveAddress = this.saveAddress.bind(this);
  }

  public confirmShipAddress(): void {
    this.props.confirmAddress();
  }

  public changeShipAddress(): void {
    this.setState({
      showChangeAddress: true
    });
  }

  public saveAddress(address: IAddress): void {
    this.props.changeAddress(address);
  }

  public render() {
    logger.trace(() => `ConfirmAddress props ${JSON.stringify(this.props)}`);
    return (
      <Col md={6} className="ml-auto mr-auto">
        <h3>Confirm Shipping Address</h3>
        <h5>{this.props.name}</h5>

        {this.props.address &&
          (this.state.showChangeAddress ? (
            <AddressEditor
              address={this.props.address}
              onSubmit={this.saveAddress}
            />
          ) : (
            <>
              <AddressDisplay address={this.props.address} />
              <FormGroup>
                <p>
                  I confirm that this is the correct address for my order to be
                  shipped to.
                </p>
                <Button color="primary" onClick={this.confirmShipAddress}>
                  Confirm Address
                </Button>{" "}
                <Button color="secondary" onClick={this.changeShipAddress}>
                  Change My Address
                </Button>
              </FormGroup>
            </>
          ))}
      </Col>
    );
  }
}

export default withRouter(ConfirmAddress);

const AddressDisplay: React.SFC<{ address: IAddress }> = ({ address }) => (
  <p>
    {address.addressLine1}
    <br />
    {address.city}, {address.state} {address.postalCode}
  </p>
);
