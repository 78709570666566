import * as React from "react";
import { Button, FormGroup, Input, Label } from "reactstrap";
import { IGiftCard, IIncentiveSelection } from "../../../models";

interface IIncentiveSelectionProperties {
  showProducts: boolean;
  giftCards: IGiftCard[];
  selectedGiftCard: number | null;
  onSubmit(selections: IIncentiveSelection): void;
}

interface IIncentiveSelectionState {
  selected: number | null;
}

export class IncentiveSelection extends React.Component<
  IIncentiveSelectionProperties,
  IIncentiveSelectionState
> {
  public state = {
    selected: this.props.selectedGiftCard
  };
  constructor(props: IIncentiveSelectionProperties, context: any) {
    super(props, context);
    this.submit = this.submit.bind(this);
  }

  public componentDidUpdate(prevProps: IIncentiveSelectionProperties) {
    if (prevProps.selectedGiftCard !== this.props.selectedGiftCard) {
      this.setState({ selected: this.props.selectedGiftCard });
    }
  }

  public handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.checked) {
      this.setState({ selected: parseInt(event.currentTarget.value, 10) });
    }
  };

  public submit() {
    const incentives: IIncentiveSelection = {
      selectedGiftCard: this.state.selected,
      selectedProducts: []
    };
    this.props.onSubmit(incentives);
  }

  public render() {
    return (
      <React.Fragment>
        <p>Please choose your incentive below</p>
        <div>
          {this.props.giftCards.map(giftCard => (
            <FormGroup key={giftCard.giftCardId} check={true}>
              <Label check={true}>
                <Input
                  type="radio"
                  name="giftCardSelection"
                  value={giftCard.giftCardId}
                  checked={this.state.selected === giftCard.giftCardId}
                  onChange={this.handleChange}
                />{" "}
                {giftCard.name}
              </Label>
            </FormGroup>
          ))}
          {this.props.showProducts && (
            <FormGroup check={true}>
              <Label check={true}>
                <Input
                  type="radio"
                  name="giftCardSelection"
                  value="0"
                  checked={this.state.selected === 0}
                  onChange={this.handleChange}
                />{" "}
                10 Free Items
              </Label>
            </FormGroup>
          )}
        </div>
        <br />
        <Button
          color="primary"
          disabled={this.state.selected === null}
          onClick={this.submit}
        >
          Continue
        </Button>
      </React.Fragment>
    );
  }
}
