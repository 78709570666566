import React from "react";
import { getLogger } from "../../../utilities/LogConfig";
import config from "../../../config";
import { GoldIncentiveSelection } from "./GoldIncentiveSelection";
import { NotEnoughItems } from "./NotEnoughItems";
import { PreferredIncentiveSelection } from "./PreferredIncentiveSelection";

import {
  IIncentiveProductSelection,
  IIncentiveSelection,
  IProduct,
  IGiftCard,
  CustomerStatus
} from "../../../models";
import { GiftCardOnlyIncentiveSelection } from "./GiftCardOnlyIncentiveSelection";
import { OrderApi } from "../../../api";

const logger = getLogger("IncentivesPage");

interface IIncentivesPageProperties {
  orderId: number;
  customerStatus: string;
  onSave(selections: IIncentiveSelection | null): void;
}

interface IState {
  giftCards: IGiftCard[];
  products: IProduct[];
  selectedGiftCard: number | null;
  selectedProducts: IIncentiveProductSelection[];
  totalSold: number;
}

export class IncentivesPage extends React.Component<
  IIncentivesPageProperties,
  IState
> {
  public state: IState = {
    giftCards: new Array<IGiftCard>(),
    products: new Array<IProduct>(),
    selectedGiftCard: null,
    selectedProducts: new Array<IIncentiveProductSelection>(),
    totalSold: 0
  };

  constructor(props: IIncentivesPageProperties, context: any) {
    super(props, context);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public async componentDidMount() {
    try {
      const availableIncentives = await OrderApi.getAvailableIncentives(
        this.props.orderId
      );
      const sellers = await OrderApi.getSellers(this.props.orderId);

      this.setState({
        ...availableIncentives,
        totalSold: sellers.reduce((acc, seller) => acc + seller.totalSold, 0)
      });
    } catch (err) {
      this.props.onSave(null);
    }
  }

  private handleSubmit(selected: IIncentiveSelection | null): void {
    if (selected !== null) {
      const giftCard = this.state.giftCards.find(gc => {
        return gc.giftCardId === selected.selectedGiftCard;
      });

      if (!giftCard) {
        logger.warn(() => "Invalid GiftCard selection");
      }
    }

    this.props.onSave(selected);
  }

  public render() {
    if (this.state.totalSold < config.numberItemsRequiredForIncentive) {
      return (
        <NotEnoughItems
          numberSold={this.state.totalSold}
          numberRequired={config.numberItemsRequiredForIncentive}
          orderId={this.props.orderId}
          saveIncentives={this.props.onSave}
        />
      );
    }

    if (this.props.customerStatus === CustomerStatus.Gold) {
      return (
        <GoldIncentiveSelection
          giftCards={this.state.giftCards}
          selectedGiftCard={this.state.selectedGiftCard}
          selectedProducts={this.state.selectedProducts}
          products={this.state.products}
          onSubmit={this.handleSubmit}
        />
      );
    } else if (this.props.customerStatus === CustomerStatus.Preferred) {
      return (
        <PreferredIncentiveSelection
          giftCards={this.state.giftCards}
          products={this.state.products}
          selectedGiftCard={this.state.selectedGiftCard}
          selectedProducts={this.state.selectedProducts}
          onSubmit={this.handleSubmit}
        />
      );
    } else {
      return (
        <GiftCardOnlyIncentiveSelection
          giftCards={this.state.giftCards}
          selectedGiftCard={this.state.selectedGiftCard}
          onSubmit={this.handleSubmit}
        />
      );
    }
  }
}

export default IncentivesPage;
