import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import valid from "card-validator";
import amexLogo from "payment-icons/min/flat/amex.svg";
import discoverLogo from "payment-icons/min/flat/discover.svg";
import mastercardLogo from "payment-icons/min/flat/mastercard-old.svg";
import visaLogo from "payment-icons/min/flat/visa.svg";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Collapse,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row
} from "reactstrap";

import { OrderApi } from "../../api/orderApi";
import { componentLogger } from "../../logger";
import {
  IAuthorizationRequest,
  PaymentType
} from "../../models/creditCardAuth";
import { IInvoice } from "../../models/invoice";
import Currency from "../Currency";
import StateSelector from "../StateSelector";
import ValidationErrorAlert from "../ValidationErrorAlert";

interface IOwnProps {
  orderId: number;
  canPayByCheck: boolean;
}

interface IComponentState {
  invoice: IInvoice;
  nameOnCard: string;
  creditCardNumber: string;
  expirationMonth: number;
  expirationYear: number;
  cvv2: string;
  email: string;
  street: string;
  city: string;
  state: string;
  postalCode: string;
  cardNumberInvalid: boolean;
  payBy: PaymentType;
  authorizationError: string;
  validationError: string;
}

export class PaymentPage extends React.Component<
  IOwnProps & RouteComponentProps<any>,
  IComponentState
> {
  public state = {
    invoice: {
      total: 0
    } as IInvoice,
    nameOnCard: "",
    creditCardNumber: "",
    expirationMonth: new Date().getMonth() + 1,
    expirationYear: new Date().getFullYear() - 2000,
    cardNumberInvalid: false,
    email: "",
    street: "",
    city: "",
    postalCode: "",
    cvv2: "",
    payBy: this.props.canPayByCheck ? undefined : PaymentType.CreditCard
  } as IComponentState;

  constructor(props: IOwnProps & RouteComponentProps<any>, context: any) {
    super(props, context);
    this.handleChange = this.handleChange.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.validateCardNumber = this.validateCardNumber.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.payByCheck = this.payByCheck.bind(this);
  }

  public componentDidMount() {
    OrderApi.getInvoice(this.props.orderId).then(invoice =>
      this.setState({ invoice })
    );
  }

  public handleChange(event: React.FormEvent<HTMLInputElement>) {
    const { name, value } = event.currentTarget;
    if (name === "cardNumber") {
      const numberValidation = valid.number(value);
      const newState = {
        creditCardNumber: value,
        cardNumberInvalid: this.state.cardNumberInvalid
      };
      if (
        !numberValidation.isPotentiallyValid !== this.state.cardNumberInvalid
      ) {
        newState.cardNumberInvalid = !numberValidation.isPotentiallyValid;
      }
      this.setState(newState);
    } else {
      this.setState({ ...this.state, [name]: value });
    }
  }

  public handleStateChange(state: string): void {
    this.setState({ state });
  }

  public setPayBy(type: PaymentType): void {
    this.setState({ payBy: type });
  }

  public payByCheck(): void {
    componentLogger.info(JSON.stringify(this.props));
    OrderApi.payByCheck(this.props.orderId)
      .then(_ => {
        this.props.history.push(`/order/${this.props.orderId}/success`);
      })
      .catch(error => {
        const errorMessage = error.response?.data ?? "A technical problem has occurred, please try again later or contact customer service.";
        console.log(error);
        componentLogger.error(JSON.stringify(error), error);
        this.setState({
          authorizationError:
            errorMessage
        });
      });
  }

  public validateCardNumber(event: React.FormEvent<HTMLInputElement>): void {
    const numberValidation = valid.number(event.currentTarget.value);
    if (!numberValidation.isValid !== this.state.cardNumberInvalid) {
      this.setState({ cardNumberInvalid: !numberValidation.isValid });
    }
  }

  public handleSubmit(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    const request: IAuthorizationRequest = {
      paymentType: this.state.payBy,
      nameOnCard: this.state.nameOnCard.trim(),
      creditCardNumber: this.state.creditCardNumber.trim(),
      expirationMonth: this.state.expirationMonth,
      expirationYear: this.state.expirationYear,
      cvv2: Number(this.state.cvv2),
      email: this.state.email.trim(),
      street: this.state.street.trim(),
      city: this.state.city.trim(),
      state: this.state.state,
      postalCode: this.state.postalCode.trim()
    };

    OrderApi.authorizeCreditCard(this.props.orderId, request)
      .then(response => {
        componentLogger.warn(JSON.stringify(response));
        if (response.isApproved) {
          // redirect
          this.props.history.push(
            `/order/${this.props.orderId}/success?paid=1`
          );
        } else {
          componentLogger.warn("Auth Failed");
          this.setState({
            authorizationError:
              "We are sorry, your credit card has been DECLINED.  Please try another card or contact your credit card company for assistance."
          });
        }
      })
      .catch(error => {
        componentLogger.error(JSON.stringify(error), error);
        this.setState({
          authorizationError:
            "A techincal problem has occured, please try again later or contact customer service."
        });
      });
  }

  public render() {
    return (
      <Container>
        <Col md={{ size: 6, offset: 3 }}>
          {this.props.canPayByCheck && (
            <>
              <Card>
                <CardBody className="text-center">
                  <CardTitle>How would you like to pay?</CardTitle>
                  <Button
                    onClick={this.setPayBy.bind(this, PaymentType.CreditCard)}
                    disabled={this.state.payBy === PaymentType.CreditCard}
                  >
                    Click Here To Pay By Credit Card
                  </Button>
                  <p>The fastest way to get your order!!</p>
                  <br />
                  <Button
                    onClick={this.setPayBy.bind(this, PaymentType.Check)}
                    disabled={this.state.payBy === PaymentType.Check}
                  >
                    Click Here To Pay By Check
                  </Button>
                  <p>The slowest way to get your order!!</p>
                </CardBody>
              </Card>
              <br />
            </>
          )}
          {this.state.authorizationError && (
                  <ValidationErrorAlert
                    errors={[this.state.authorizationError]}
                  />
                )}
          <Collapse isOpen={this.state.payBy === PaymentType.CreditCard}>
            <Card>
              <CardHeader> Pay By Credit Card</CardHeader>
              <CardBody>
                <Row>
                  <Col md={6}>
                    <div>
                      <h4>Payment Amount</h4>
                      <h5>
                        <Currency value={this.state.invoice.total} />
                      </h5>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="text-center">
                      <img src={visaLogo} alt="visa" width="48" />
                      &nbsp;
                      <img src={mastercardLogo} alt="mastercard" width="48" />
                      &nbsp;
                      <img src={discoverLogo} alt="discover" width="48" />
                      &nbsp;
                      <img src={amexLogo} alt="american express" width="48" />
                    </div>
                  </Col>
                </Row>
                <hr />
  
                <Form onSubmit={this.handleSubmit}>
                  <FormGroup tag="fieldset">
                    <legend>Card Details</legend>
                    <FormGroup>
                      <Label for="nameOnCard">Name on card</Label>
                      <Input
                        type="text"
                        name="nameOnCard"
                        value={this.state.nameOnCard}
                        onChange={this.handleChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="cardNumber">Card Number</Label>
                      <Input
                        type="text"
                        name="cardNumber"
                        placeholder="XXXX XXXX XXXX XXXX"
                        required={true}
                        invalid={this.state.cardNumberInvalid}
                        onBlur={this.validateCardNumber}
                        value={this.state.creditCardNumber}
                        onChange={this.handleChange}
                      />
                      <FormFeedback invalid="true">
                        Invalid credit card number.
                      </FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Row>
                        <Col md={4}>
                          <Label for="expirationMonth">Expiration Month</Label>
                          <Input
                            type="select"
                            name="expirationMonth"
                            value={this.state.expirationMonth}
                            required={true}
                            onChange={this.handleChange}
                          >
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                          </Input>
                        </Col>
                        <Col md={4}>
                          <Label for="expirationYear">Expiration Year</Label>
                          <Input
                            type="select"
                            name="expirationYear"
                            value={this.state.expirationYear}
                            required={true}
                            onChange={this.handleChange}
                          >
                            {Array.from(Array(20).keys()).map(n => {
                              const year = new Date().getFullYear();
                              return (
                                <option value={year - 2000 + n} key={n}>
                                  {year + n}
                                </option>
                              );
                            })}
                          </Input>
                        </Col>
                        <Col md={3}>
                          <Label for="cvv2">Security Code</Label>
                          <Input
                            type="text"
                            name="cvv2"
                            value={this.state.cvv2}
                            required={true}
                            onChange={this.handleChange}
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  </FormGroup>
                  <FormGroup tag="fieldset">
                    <legend>Billing Address</legend>
                    <FormGroup>
                      <Label for="email">Email</Label>
                      <Input
                        type="email"
                        required={true}
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="street">Address</Label>
                      <Input
                        type="text"
                        required={true}
                        name="street"
                        value={this.state.street}
                        onChange={this.handleChange}
                      />
                    </FormGroup>
                    <Row>
                      <Col>
                        <FormGroup inline={true}>
                          <Label for="city">City</Label>
                          <Input
                            type="text"
                            name="city"
                            value={this.state.city}
                            onChange={this.handleChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <Label for="state">State</Label>
                        <StateSelector
                          selected={this.state.state}
                          onChange={this.handleStateChange}
                        />
                      </Col>
                      <Col md={3}>
                        <Label for="postalCode">Zip Code</Label>
                        <Input
                          type="text"
                          required={true}
                          name="postalCode"
                          value={this.state.postalCode}
                          onChange={this.handleChange}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <Button color="primary" size="lg" block={true}>
                    Pay <Currency value={this.state.invoice.total} /> By Credit
                    Card
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Collapse>
          <Collapse isOpen={this.state.payBy === PaymentType.Check}>
            <Card>
              <CardHeader>Pay By Check</CardHeader>
              <CardBody>
                To pay for your order by check, click submit below and follow
                the instructions on your invoice.
                <br />
                <br />
                <Button
                  color="primary"
                  size="lg"
                  block={true}
                  onClick={this.payByCheck}
                >
                  Pay <Currency value={this.state.invoice.total} /> By Check
                </Button>
              </CardBody>
            </Card>
          </Collapse>
        </Col>
      </Container>
    );
  }
}

export default withRouter(PaymentPage);
