import * as React from "react";
import { useFormik } from "formik";
import { Form, FormGroup, Label, Input, Col, Button, ListGroup, ListGroupItem, Row } from "reactstrap";
import { parseJSON } from "date-fns";
import fileDownload from "js-file-download";
import { OrderApi } from "../../../api";
import { IGroupSearchResult } from "../../../models/groupSearchResult";

const downloadSellerReport = async (orderId: number) => {
    const report = await OrderApi.getSellerReportByOrderId(orderId);
    fileDownload(report, `SellerReport-${orderId}.xls`);
};

const BookingSearch: React.FC = () => {
    const [groupName, setGroupName] = React.useState("");
    const [groups, setGroups] = React.useState<IGroupSearchResult[]>([]);
    React.useEffect(() => {
        if (!groupName) return;
        const findGroups = async () => {
            const groups = await OrderApi.findOrdersByGroupName(groupName);
            setGroups(groups)
        }

        findGroups();
    }, [groupName]);
  const { values, handleChange, handleSubmit, getFieldProps } = useFormik({
    onSubmit(values, formikHelpers) {
        setGroupName(values.groupName)
    },
    initialValues: {
      groupName: "",
    },
  });
  return (
    <Col md={6} className="ml-auto mr-auto">
      <h3>Booking Search</h3>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="groupName">Group Name</Label>
          <Input
            type="text"
            {...getFieldProps("groupName")}
          />
        </FormGroup>
        <Button color="primary" type="submit">Search</Button>
      </Form>
      
      {groups.length > 0 && (
        <ListGroup flush>
            {groups.map((g) => (
                <ListGroupItem key={g.id} tag="button" onClick={() => {downloadSellerReport(g.bookingId)}} action>
                    <Row>

                        <Col md={6}>
                            <strong>{g.name}</strong><br />
                            {g.contact} <br />
                            {g.phoneNumber} <br />
                            {g.emailAddress} <br />
                        </Col>
                
                        <Col md={6} className="align-self-end text-right">
                            <strong>{parseJSON(g.startDate).toLocaleDateString()} - {parseJSON(g.endDate).toLocaleDateString()}</strong>
                            
                            <address>
                                {g.address.addressLine1}<br />
                                {g.address.addressLine2 && <>{g.address.addressLine2}<br /></>}
                                {g.address.city}, {g.address.state} {g.address.postalCode}
                            </address>
                            
                        </Col>
                    </Row>
                    
                </ListGroupItem>
            ))}
         </ListGroup>
      )}
    </Col>
  );
};

const HomePage: React.FC = () => {
  return (
    <div>
      <BookingSearch />
    </div>
  );
};

export default HomePage;
