import * as React from "react";
import { Container } from "reactstrap";
import {
  IGiftCard,
  IIncentiveProductSelection,
  IIncentiveSelection,
  IProduct
} from "../../../models";
import { IncentiveSelection } from "./IncentiveSelection";
import { ProductIncentiveSelectionForm } from "./ProductIncentiveSelectionForm";

interface IPreferredIncentiveSelectionProperties {
  selectedGiftCard: number | null;
  selectedProducts: IIncentiveProductSelection[];
  giftCards: IGiftCard[];
  products: IProduct[];
  onSubmit(selections: IIncentiveSelection): void;
}

export class PreferredIncentiveSelection extends React.Component<
  IPreferredIncentiveSelectionProperties
> {
  public state = {
    showProductSelectionForm: false
  };

  public onSubmit = (selected: IIncentiveSelection) => {
    if (
      !this.state.showProductSelectionForm &&
      selected.selectedGiftCard === 0
    ) {
      this.setState({ showProductSelectionForm: true });
    } else {
      this.props.onSubmit(selected);
    }
  };

  public render() {
    return (
      <Container>
        <h2>Select Incentive</h2>
        <p>
          Thank you for booking your sale! As a reward for being a Preferred
          member you get a $25 gift card to one of the merchants below OR 10{" "}
          <strong>FREE</strong> items for booking your sale.
        </p>
        {!this.state.showProductSelectionForm && (
          <IncentiveSelection
            giftCards={this.props.giftCards}
            selectedGiftCard={this.props.selectedGiftCard}
            showProducts={true}
            onSubmit={this.onSubmit}
          />
        )}
        {this.state.showProductSelectionForm && (
          <ProductIncentiveSelectionForm
            products={this.props.products}
            selectedProducts={this.props.selectedProducts}
            onSubmit={this.onSubmit}
          />
        )}
      </Container>
    );
  }
}
