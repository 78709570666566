import React from "react";
import { Route, withRouter, RouteComponentProps } from "react-router-dom";
import { getLogger } from "../../utilities/LogConfig";

import SellersSummary from "./SellersSummary";
import { OrderApi } from "../../api";
import { ISeller, IBrochure, CustomerStatus } from "../../models";
import SellerEditor from "./SellerEditor";
import { joinUrl } from "../../utilities/UrlUtilities";
import memoize from "memoize-one";

const logger = getLogger("Sellers");

interface IOwnProps {
  orderId: number;
  customerStatus: CustomerStatus;
  brochures: IBrochure[];
}

interface IRouteParams {
  sellerId?: string;
}

interface IState {
  sellers: ISeller[];
  editingSeller?: number;
}

export class Sellers extends React.Component<
  IOwnProps & RouteComponentProps<IRouteParams>,
  IState
> {
  public state = {
    sellers: new Array<ISeller>(),
    hasMetMinimum: false,
  };

  constructor(
    props: IOwnProps & RouteComponentProps<IRouteParams>,
    context: any
  ) {
    super(props, context);

    this.getSellers = this.getSellers.bind(this);
    this.renderSellersSummary = this.renderSellersSummary.bind(this);
    this.renderAddSellersPage = this.renderAddSellersPage.bind(this);
    this.renderEditSellersPage = this.renderEditSellersPage.bind(this);
    this.saveSeller = this.saveSeller.bind(this);
    this.deleteSeller = this.deleteSeller.bind(this);
  }

  checkIfMetMinimum = memoize(
    (brochures: IBrochure[], sellers: ISeller[]) =>
      brochures.every((b) => !b.isFrozen) ||
      sellers.reduce((acc, s) => (acc += s.totalSold), 0) >= 90
  );

  public componentDidMount() {
    logger.trace(() => `Component mounted, order id ${this.props.orderId}`);

    if (this.props.orderId !== 0) {
      this.getSellers(this.props.orderId);
    }
  }

  public componentDidUpdate(prevProps: IOwnProps & RouteComponentProps) {
    logger.trace(() => "Component Did Update");

    if (this.props.orderId !== prevProps.orderId) {
      this.getSellers(this.props.orderId);
    }
  }

  private async getSellers(orderId: number) {
    logger.trace(() => `getting sellers for orderId ${orderId}`);
    const sellers = await OrderApi.getSellers(orderId);
    logger.trace(() => `Got sellers ${JSON.stringify(sellers)}`);
    this.setState({ sellers });
  }

  private async saveSeller(seller: ISeller, finished: boolean) {
    logger.trace(() => `saving seller ${JSON.stringify(seller)}`);
    const savedSeller = await OrderApi.saveSeller(this.props.orderId, seller);
    const otherSellers = this.state.sellers.filter(
      (s) => s.sellerId !== seller.sellerId
    );
    this.setState({ sellers: [savedSeller, ...otherSellers] });

    if (finished) {
      logger.trace(
        () => "finished editing sellers, redirecting back to sellers summary"
      );
      this.props.history.push(`/order/${this.props.orderId}/sellers`);
    }
  }

  private async deleteSeller(seller: ISeller) {
    logger.trace(() => `Deleting seller with id ${seller.sellerId}`);
    const result = await OrderApi.deleteSeller(
      this.props.orderId,
      seller.sellerId
    );
    if (result) {
      this.setState({
        sellers: this.state.sellers.filter(
          (s) => s.sellerId !== seller.sellerId
        ),
      });
    }
  }

  private renderSellersSummary() {
    const hasMetMinimum = this.checkIfMetMinimum(
      this.props.brochures,
      this.state.sellers
    );
    return (
      <SellersSummary
        sellers={this.state.sellers}
        deleteSeller={this.deleteSeller}
        customerStatus={this.props.customerStatus}
        hasMetMinimum={hasMetMinimum}
      />
    );
  }

  private renderAddSellersPage() {
    return (
      <SellerEditor
        orderId={this.props.orderId}
        saveSeller={this.saveSeller}
        brochures={this.props.brochures}
        isEditMode={false}
      />
    );
  }

  private renderEditSellersPage(
    props: RouteComponentProps<{ sellerId?: string }>
  ) {
    logger.trace(() => "Rendering edit seller control");
    if (!props.match.params.sellerId) {
      logger.warn(
        () => `Unable to find seller id in match url ${props.match.url}`
      );
    }
    const sellerId = Number(props.match.params.sellerId);
    const sellerToEdit = this.state.sellers.find(
      (s) => s.sellerId === sellerId
    );
    logger.trace(() => `found seller to edit ${JSON.stringify(sellerToEdit)}`);

    return (
      <SellerEditor
        orderId={this.props.orderId}
        saveSeller={this.saveSeller}
        brochures={this.props.brochures}
        seller={sellerToEdit}
        isEditMode={true}
      />
    );
  }

  render() {
    return (
      <>
        <Route
          exact
          path={this.props.match.url}
          render={this.renderSellersSummary}
        />

        <Route
          path={joinUrl(this.props.match.url, "add")}
          render={this.renderAddSellersPage}
        />
        <Route
          path={joinUrl(this.props.match.url, ":sellerId/edit")}
          render={this.renderEditSellersPage}
        />
      </>
    );
  }
}

export default withRouter(Sellers);
