export const joinUrl = (base: string, path: string) => {
  const trimmedBase =
    base.charAt(base.length - 1) === "/"
      ? base.substr(0, base.length - 1)
      : base;
  const trimmedPath =
    path.charAt(0) === "/" ? path.substr(1, path.length - 1) : path;

  return `${trimmedBase}/${trimmedPath}`;
};
