import "bootstrap/dist/css/bootstrap.min.css";
import React, { Component } from "react";
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter
} from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { PrivateRoute } from "./privateRoute";
import queryString from "query-string";
import { getLogger } from "./utilities/LogConfig";
import axios from "axios";

import "./App.css";

import { AuthApi, BrandingApi } from "./api";

import ForgotPasswordPage from "./components/auth/ForgotPasswordPage";
import LoginPage from "./components/auth/LoginPage";
import Header from "./components/Header";
import LoadingSpinner from "./components/LoadingSpinner";
import OrderSelectionPage from "./components/order/OrderSelectionPage";
import { IBranding, IUserInfo, Roles, IFundraiser, Order } from "./models";
import OrderEntryPage from "./components/order/OrderEntryPage";
import HomePage from "./components/pages/HomePage";

const logger = getLogger("AppComponent");

interface IRouteProps {
  token?: string;
}

interface IOwnProps {}

interface IState {
  isAuthenticated: boolean;
  userInfo: IUserInfo | null;
  branding: IBranding;
  groupName: string;
  requestCount: number;
  errorMessage: string;
  availableFundraisers: IFundraiser[];
}

export class App extends Component<
  IOwnProps & RouteComponentProps<IRouteProps>,
  IState
> {
  public state = {
    requestCount: 0,
    branding: {} as IBranding,
    isAuthenticated: AuthApi.getUserInfo() !== null
  } as IState;

  constructor(
    props: IOwnProps & RouteComponentProps<IRouteProps>,
    context: any
  ) {
    super(props, context);
    this.renderLoginPage = this.renderLoginPage.bind(this);
    this.renderOrderSelectionPage = this.renderOrderSelectionPage.bind(this);
    this.renderOrderEntryPage = this.renderOrderEntryPage.bind(this);
    this.loginUser = this.loginUser.bind(this);
    this.logoutUser = this.logoutUser.bind(this);
    this.selectGroup = this.selectGroup.bind(this);
    this.orderReceivedEvent = this.orderReceivedEvent.bind(this);
    this.setupInterceptor();
  }

  public async componentDidMount() {
    logger.info(() => "Component Mounted");
    const userInfo = AuthApi.getUserInfo();
    logger.trace(() => `User Info ${JSON.stringify(userInfo)}`);
    BrandingApi.getBranding().then(branding => {
      logger.info(`Branding ${branding.brandingName}`);
      this.setState({ branding, isAuthenticated: userInfo !== null });
    });

    if (this.props.location.search) {
      logger.info(() => `Query string ${this.props.location.search} provided`);
      var params = queryString.parse(this.props.location.search);
      logger.info(() => `Authenticating token ${params.token}`);
      await this.authenticateToken(params.token as string);
    } else if (userInfo === null) {
      this.props.history.push("/login", { referrer: this.props.location });
    }
  }

  private setupInterceptor() {
    logger.trace("Setting up axios interceptors");
    axios.interceptors.request.use(
      config => {
        logger.trace("Making request");
        this.setState(state => {
          return {
            requestCount: state.requestCount + 1
          };
        });
        return config;
      },
      error => {
        logger.trace("error making request");
        this.setState(state => {
          return {
            requestCount: state.requestCount - 1
          };
        });
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      response => {
        logger.trace("response success");
        this.setState(state => {
          return {
            requestCount: state.requestCount - 1
          };
        });
        return response;
      },
      error => {
        logger.trace("error success");
        this.setState(state => {
          return {
            requestCount: state.requestCount - 1
          };
        });

        return Promise.reject(error);
      }
    );
  }

  private async authenticateToken(token: string) {
    AuthApi.authenticateToken(token).then(availableFundraisers => {
      logger.info(
        () => `User has ${availableFundraisers.length} fundraisers available`
      );
      if (availableFundraisers.length === 1) {
        logger.info("Only one fundraiser found");
        this.setState(
          {
            availableFundraisers,
            isAuthenticated: true
          },
          () => {
            this.selectGroup(availableFundraisers[0].id);
            // this.props.history.push(`/order/${availableFundraisers[0].id}`);
          }
        );
      } else if (availableFundraisers.length > 1) {
        logger.info(
          () => "Multiple fundraisers found, redirect to selection page"
        );
        this.setState({ availableFundraisers, isAuthenticated: true }, () => {
          this.props.history.push("/select-fundraiser");
        });
      }
    });
  }

  public logoutUser() {
    AuthApi.logoutUser().then(_ => {
      this.setState({ isAuthenticated: false, userInfo: null });
    });
  }

  public loginUser(username: string, password: string) {
    AuthApi.authenticateUser(username, password)
      .then(response => {
        this.setState({ userInfo: response, isAuthenticated: true }, () => {
          if (response.role === Roles.Customer) {
            logger.info("redirecting to order id");
            this.props.history.push(`/order/${response.orderId}`);
          } else {
            logger.info(JSON.stringify(this.props.location));
            if (
              this.props.location.state &&
              this.props.location.state.referrer !== undefined
            ) {
              logger.info("redirecting to referrer");
              this.props.history.push(this.props.location.state.referrer);
            } else {
              logger.info("redirecting to homepage");
              this.props.history.push("/");
            }
          }
        });
      })
      .catch(_ => {
        this.setState({
          errorMessage: "Invalid username or password"
        });
      });
  }

  public renderLoginPage() {
    return <LoginPage loginUser={this.loginUser} />;
  }

  public renderOrderSelectionPage() {
    return (
      <OrderSelectionPage
        availableFundraisers={this.state.availableFundraisers}
        selectGroup={this.selectGroup}
      />
    );
  }

  public renderOrderEntryPage() {
    const userInfo = AuthApi.getUserInfo();
    if (userInfo === null) return null;

    return (
      <OrderEntryPage
        userInfo={userInfo}
        branding={this.state.branding}
        onOrderReceived={this.orderReceivedEvent}
      />
    );
  }

  public selectGroup(orderId: number) {
    logger.info(`Selecting order id ${orderId}`);
    const selectedGroup = this.state.availableFundraisers.find(
      f => f.id === orderId
    );
    if (selectedGroup) {
      this.setState({ groupName: selectedGroup.groupName });
      this.props.history.push(`/order/${orderId}`);
    }
  }

  private orderReceivedEvent(order: Order) {
    this.setState({ groupName: order.groupName });
  }

  public render() {
    return (
      <div className="App">
        <Header
          groupName={this.state.groupName}
          logout={this.logoutUser}
          brandName={this.state.branding.brandingName}
          isAuthenticated={this.state.isAuthenticated}
        />
        <Container fluid={true} className="app__page_container">
          {this.state.requestCount > 0 && <LoadingSpinner />}
          <Row>
            <Col />
          </Row>
          <Row>
            <Col>
              <Switch>
                {/* <Route path="/not-found" component={NotFoundPage} /> */}
                <Route path="/forgot-password" component={ForgotPasswordPage} />
                <Route path="/login" render={this.renderLoginPage} />
                <PrivateRoute
                  exact={true}
                  path="/select-fundraiser"
                  isAuthenticated={this.state.isAuthenticated}
                  render={this.renderOrderSelectionPage}
                />
                <PrivateRoute
                  path="/order/:orderId"
                  isAuthenticated={this.state.isAuthenticated}
                  render={this.renderOrderEntryPage}
                />
                <PrivateRoute
                  path="/"
                  exact={true}
                  isAuthenticated={this.state.isAuthenticated}
                  component={HomePage}
                  />
                {/*<PrivateRoute path="/add-seller" component={AddSellerPage} /> */}
              </Switch>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default withRouter(App);
