export enum IProfitOptionType {
  NotSelected = 0,
  BySeller = 1,
  Bulk = 2
}

export interface IProfitDetail {
  productCode: string;
  productDescription: string;
  profitPerItem: number;
  quantity: number;
  totalProfitFromItem: number;
}

export interface IProfitOption {
  type: IProfitOptionType;
  isDefault: boolean;
  totalProfit: number;
  profitDetail: IProfitDetail[];
}
