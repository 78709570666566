import React from "react";
import querystring from "query-string";
import { Button, Container } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getLogger } from "../../utilities/LogConfig";
import { withRouter, RouteComponentProps } from "react-router-dom";

const logger = getLogger("SuccessPage");

interface IOwnProps {
  orderId: number;
}

interface IRouteParams {
  paid?: string;
}

type Props = IOwnProps & RouteComponentProps<IRouteParams>;

const SuccessMessage = ({ paid }: { paid: boolean }) => {
  logger.debug(() => `Paid ${paid}`);
  return paid ? (
    <>
      <h2>Your order has been submitted and paid with a credit card.</h2>
      <p>Please print a copy of your order for your records.</p>
      <p>
        Look at the seller report, it will list what they sold and who gets a
        free prize!
      </p>
    </>
  ) : (
    <>
      <h2>Your order has been submitted!</h2>
      <p>It is important to mail your check and a copy of your order ASAP.</p>
      <p>
        Your order will start the delivery process the day we receive your check
        and a copy of your order in our office.
      </p>
      <p>Please print your order and mail your check ASAP.</p>
    </>
  );
};

const SuccessPage: React.FunctionComponent<Props> = (props: Props) => {
  logger.trace(() => `Displaying success for ${props.orderId}`);
  logger.trace(() => `Props ${JSON.stringify(props)}`);
  var queryParams = querystring.parse(props.location.search);
  logger.trace(() => `Query Params ${JSON.stringify(queryParams.paid)}`);

  return (
    <Container>
      <SuccessMessage paid={!!queryParams.paid} />
      <div>
        <Button
          tag="a"
          href={`/api/order/${props.orderId}/invoice/pdf`}
          target="_blank"
          color="primary"
        >
          <FontAwesomeIcon icon="file-invoice-dollar" />
          &nbsp;&nbsp;Click Here To Print Your Invoice
        </Button>
      </div>
    </Container>
  );
};

export default withRouter(SuccessPage);
