import * as React from "react";
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row
} from "reactstrap";

import { IAddress } from "../models";
import StateSelector from "./StateSelector";

interface IOwnProps {
  address: IAddress;
  onSubmit(address: IAddress): void;
}

export default class AddressEditor extends React.Component<
  IOwnProps,
  IAddress
> {
  public state = {
    addressLine1: this.props.address.addressLine1,
    addressLine2: this.props.address.addressLine2,
    city: this.props.address.city,
    state: this.props.address.state,
    postalCode: this.props.address.postalCode
  };

  constructor(props: IOwnProps, context: any) {
    super(props, context);

    this.handleChange = this.handleChange.bind(this);
    this.stateChange = this.stateChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  public handleChange(event: React.FormEvent<HTMLInputElement>): void {
    const { name, value } = event.currentTarget;
    const newState = { ...this.state, [name]: value };
    this.setState(newState);
  }

  public stateChange(selected: string): void {
    this.setState({
      ...this.state,
      state: selected
    });
  }

  public submit(): void {
    this.props.onSubmit(this.state);
  }

  public render() {
    return (
      <Container>
        <FormGroup>
          <Label>Street Address</Label>
          <Input
            type="text"
            defaultValue={this.state.addressLine1}
            name="addressLine1"
            onChange={this.handleChange}
          />
        </FormGroup>
        <Row>
          <Col md={4}>
            <FormGroup>
              <Label>City</Label>
              <Input
                type="text"
                defaultValue={this.state.city}
                name="city"
                onChange={this.handleChange}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label>State</Label>
              <StateSelector
                selected={this.state.state}
                onChange={this.stateChange}
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label>Zip</Label>
              <Input
                type="text"
                defaultValue={this.state.postalCode}
                name="postalCode"
                onChange={this.handleChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Button onClick={this.submit}>Save Address</Button>
        </Row>
      </Container>
    );
  }
}
