import * as React from "react";
import { Route, RouteComponentProps } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { getLogger } from "../../utilities/LogConfig";

import {
  IAddress,
  IIncentiveSelection,
  Order,
  IUserInfo,
  IBranding,
  CustomerStatus
} from "../../models";

import { Roles } from "../../models";
import { joinUrl } from "../../utilities/UrlUtilities";
import { OrderApi, AuthApi, BrandingApi } from "../../api";
import ConfirmAddress from "./ConfirmAddress";
import DeliveryInfoPage from "./DeliveryInfoPage";
import IncentivesPage from "./Incentives/IncentivesPage";
import ProfitPage from "./ProfitPage";
import Sellers from "./Sellers";
import SuccessPage from "./SuccessPage";
import PaymentPage from "./PaymentPage";

const logger = getLogger("OrderEntryPage");

interface IOrderEntryPageProperties {
  userInfo: IUserInfo;
  branding: IBranding;
  onOrderReceived?(order: Order): void;
}

interface IOrderEntryPageState {
  orderId: number;
  error: string;
  order: Order;
}

export class OrderEntryPage extends React.Component<
  IOrderEntryPageProperties & RouteComponentProps<{ orderId?: string }>,
  IOrderEntryPageState
> {
  public state: IOrderEntryPageState = {
    error: "",
    order: new Order(),
    orderId: Number(this.props.match.params.orderId)
  };

  constructor(
    props: IOrderEntryPageProperties &
      RouteComponentProps<{ orderId?: string }>,
    context: any
  ) {
    super(props, context);

    this.saveIncentiveSelections = this.saveIncentiveSelections.bind(this);
    this.saveShippingAddress = this.saveShippingAddress.bind(this);
    this.confirmShippingAddress = this.confirmShippingAddress.bind(this);
    this.renderConfirmAddress = this.renderConfirmAddress.bind(this);
    this.renderIncentivesPage = this.renderIncentivesPage.bind(this);
    this.renderProfit = this.renderProfit.bind(this);
    this.renderDeliveryInfo = this.renderDeliveryInfo.bind(this);
    this.renderSellers = this.renderSellers.bind(this);
    this.renderPaymentPage = this.renderPaymentPage.bind(this);
    this.renderSuccess = this.renderSuccess.bind(this);
  }

  public componentDidMount() {
    const orderId = Number(this.props.match.params.orderId);
    logger.trace(() => `Component mounted, order id ${orderId}`);
    this.setState({ orderId });
    const userInfo = AuthApi.getUserInfo();
    if (userInfo === null) {
      logger.error(
        () => "User Info not available",
        new Error("User info not available")
      );
      this.props.history.push("/error");
      return;
    }
    OrderApi.getOrder(orderId)
      .then(order => {
        logger.trace(() => `Successfully got order ${orderId}`);
        this.setState({ order }, this.redirectIfFinalized);
        if (this.props.onOrderReceived) {
          logger.trace("onOrderReceived event defined, calling");
          this.props.onOrderReceived(order);
        }
      })
      .catch(error => {
        logger.trace(() => `Error getting order ${orderId}`);
        logger.error(
          () => `OrderApi.getOrder(${orderId}) threw an error`,
          error
        );
        this.setState({ error });
      });
  }

  componentDidUpdate() {
    this.redirectIfFinalized();
  }

  private redirectIfFinalized() {
    const userInfo = AuthApi.getUserInfo();
    const order = this.state.order;
    const path = this.props.location.pathname;
    const successPagePath = `${this.props.match.url}/success`;
    if (userInfo === null) return;

    if (
      path !== successPagePath &&
      order.isFinalized &&
      userInfo.role !== Roles.Admin
    ) {
      logger.trace(
        () =>
          "Order finalized & user is not an admin, redirecting to success page."
      );
      this.props.history.push(successPagePath);
    }
  }

  public async saveIncentiveSelections(selections: IIncentiveSelection | null) {
    if (selections !== null) {
      OrderApi.saveIncentiveSelections(this.state.orderId, selections);
    }

    const branding = await BrandingApi.getBranding();
    const nextPage = branding.isDeliveredByFreight ? "delivery-info" : "profit";
    console.log("redirecting to", `/order/${this.state.orderId}/${nextPage}`);
    this.props.history.push(`/order/${this.state.orderId}/${nextPage}`);
  }

  public saveShippingAddress(address: IAddress): void {
    OrderApi.saveShippingAddress(this.state.order.orderId, address).then(
      savedAddress => {
        this.setState(
          {
            order: Object.assign(new Order(), {
              ...this.state.order,
              shippingAddress: savedAddress
            })
          },
          () => this.confirmShippingAddress()
        );
      }
    );
  }

  public confirmShippingAddress(): void {
    this.props.history.push(joinUrl(this.props.match.url, "sellers"));
  }

  public renderConfirmAddress(): JSX.Element {
    return (
      <ConfirmAddress
        name={this.state.order.groupName}
        address={this.state.order.shippingAddress}
        changeAddress={this.saveShippingAddress}
        confirmAddress={this.confirmShippingAddress}
      />
    );
  }

  public renderSellers(): JSX.Element {
    return (
      <Sellers
        orderId={this.state.order.orderId}
        brochures={this.state.order.brochures}
        customerStatus={this.state.order.customerStatus}
      />
    );
  }

  public renderIncentivesPage(): JSX.Element {
    logger.info(() => `Customer Status: ${this.state.order.customerStatus}`);
    return (
      <IncentivesPage
        orderId={this.state.orderId}
        onSave={this.saveIncentiveSelections}
        customerStatus={this.state.order.customerStatus}
      />
    );
  }

  public renderProfit(): JSX.Element {
    return (
      <ProfitPage
        orderId={this.state.orderId}
        canPayByCreditCard={this.props.branding.canPayByCreditCard}
      />
    );
  }

  public renderPaymentPage() {
    return (
      <PaymentPage
        orderId={this.state.orderId}
        canPayByCheck={this.props.branding.canPayByCheck}
      />
    );
  }

  public renderDeliveryInfo() {
    return <DeliveryInfoPage orderId={this.state.orderId} />;
  }

  public renderSuccess() {
    logger.trace(
      `Rendering SuccessPage component for ${this.props.match.params.orderId}`
    );
    return <SuccessPage orderId={Number(this.props.match.params.orderId)} />;
  }

  public render(): JSX.Element {
    return (
      <div>
        <Route
          exact={true}
          path={`${this.props.match.url}`}
          render={this.renderConfirmAddress}
        />

        <Route
          path={joinUrl(this.props.match.url, "sellers")}
          render={this.renderSellers}
        />

        <Route
          path={joinUrl(this.props.match.url, "incentives")}
          render={this.renderIncentivesPage}
        />

        <Route
          path={joinUrl(this.props.match.url, "delivery-info")}
          render={this.renderDeliveryInfo}
        />

        <Route
          path={joinUrl(this.props.match.url, "profit")}
          render={this.renderProfit}
        />

        <Route
          path={joinUrl(this.props.match.url, "payment")}
          render={this.renderPaymentPage}
        />

        <Route
          path={joinUrl(this.props.match.url, "success")}
          render={this.renderSuccess}
        />
      </div>
    );
  }
}

export default withRouter(OrderEntryPage);
