export * from "./address";
export * from "./branding";
export * from "./giftcard";
export * from "./incentives";
export * from "./order";
export * from "./product";
export * from "./roles";
export * from "./seller";
export * from "./userInfo";
export * from "./fundraiser";
export * from "./contact";
export * from "./creditCardAuth";
export * from "./invoice";
export * from "./profit";

export enum CustomerStatus {
  Gold = "Gold",
  Preferred = "Preferred",
  GiftCard = "giftcard",
  None = "none"
}
