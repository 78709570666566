import * as React from "react";
import { Container } from "reactstrap";
import {
  IGiftCard,
  IIncentiveProductSelection,
  IProduct
} from "../../../models";
import { IncentiveSelection } from "./IncentiveSelection";
import { ProductIncentiveSelectionForm } from "./ProductIncentiveSelectionForm";

interface IGoldIncentiveelectionProperties {
  selectedGiftCard: number | null;
  selectedProducts: IIncentiveProductSelection[];
  giftCards: IGiftCard[];
  products: IProduct[];
  onSubmit(selected: any): void;
}

export class GoldIncentiveSelection extends React.Component<
  IGoldIncentiveelectionProperties
> {
  public render() {
    return (
      <Container>
        <h2>Select Incentive</h2>
        <p>
          Thank you for booking your sale! As a reward for being a Gold member
          you get a $25 gift card to one of the merchants below AND 10{" "}
          <strong>FREE</strong> items for booking your sale.
        </p>
        <IncentiveSelection
          giftCards={this.props.giftCards}
          selectedGiftCard={this.props.selectedGiftCard}
          showProducts={false}
          onSubmit={this.props.onSubmit}
        />
        <ProductIncentiveSelectionForm
          products={this.props.products}
          onSubmit={this.props.onSubmit}
          selectedProducts={this.props.selectedProducts}
        />
      </Container>
    );
  }
}
