import * as React from "react";
import { Redirect, RouteProps, Route } from "react-router";
import { getLogger } from "./utilities/LogConfig";

const logger = getLogger("PrivateRoute");

interface IPrivateRouteProps extends RouteProps {
  isAuthenticated: boolean;
  authenticationPath?: string;
}

export class PrivateRoute extends Route<IPrivateRouteProps> {
  public render() {
    let redirectPath: string = "";
    if (!this.props.isAuthenticated) {
      logger.info(() => "Not authenticated, setting up redirect");
      redirectPath = this.props.authenticationPath
        ? this.props.authenticationPath
        : "/login";
    }

    if (redirectPath) {
      logger.info(() => `Redirecting to ${redirectPath}`);
      const renderComponent = () => (
        <Redirect
          to={{
            pathname: redirectPath,
            state: {
              referrer: this.props.location
            }
          }}
        />
      );

      return (
        <Route {...this.props} component={renderComponent} render={undefined} />
      );
    } else {
      logger.info(() => "Authenticated, rendering route");
      return <Route {...this.props} />;
    }
  }
}
