import * as React from "react";
import { Link } from "react-router-dom";
import { Button, Container } from "reactstrap";
import { IIncentiveSelection } from "../../../models";

export interface INotEnoughItemsProperties {
  numberSold: number;
  numberRequired: number;
  orderId: number;
  saveIncentives(selections: IIncentiveSelection | null): void;
}

export const NotEnoughItems: React.SFC<INotEnoughItemsProperties> = ({
  numberSold,
  numberRequired,
  orderId,
  saveIncentives
}: INotEnoughItemsProperties) => {
  function skipIncentives() {
    saveIncentives(null);
  }
  return (
    <Container>
      <h2>We're Sorry</h2>
      <p>
        In order to receive your booking incentive of a $25 gift card or 10 free
        items, you must sell {numberRequired} or more items. You have currently
        sold only {numberSold} items. If you sell just{" "}
        {numberRequired - numberSold} more items you will become eligible to
        receive your booking incentive.
      </p>
      <p>
        We recommend that you add {numberRequired - numberSold} items to your
        order so that you will receive your booking incentive. You may then sell
        these extra items to parents over the next few weeks.
      </p>
      <div>
        <Button color="primary" tag={Link} to={`/order/${orderId}/sellers/add`}>
          Add another seller to order
        </Button>
        &nbsp;
        <Button color="secondary" onClick={skipIncentives}>
          Submit without receiving the booking incentive
        </Button>
      </div>
    </Container>
  );
};
