import { CustomerStatus } from ".";
import { IAddress } from "./address";
import { IProduct } from "./product";
import { IProfitOptionType } from "./profit";


export interface IBrochure {
  brochureId: number;
  maxProfit: number;
  brochureName: string;
  products: IProduct[];
  isFrozen: boolean;
}

export class Order {
  public orderId: number = 0;
  public profitTableId: number = 0;
  public profitOptionSelected: IProfitOptionType =
    IProfitOptionType.NotSelected;
  public groupName: string = "";
  public contactName: string = "";
  public address: IAddress = {} as IAddress;
  public customerStatus: CustomerStatus = CustomerStatus.None;
  public shippingName: string = "";
  public shippingContact: string = "";
  public shippingAddress: IAddress = {} as IAddress;
  public phoneNumber: string = "";
  public isFinalized: boolean = false;
  public numberOfChildren: number = 0;
  public brochures: IBrochure[] = [];
}
