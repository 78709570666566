import axios from "axios";
import { IUserInfo, IFundraiser } from "../models";
import { getLogger } from "../utilities/LogConfig";

const logger = getLogger("AuthApi");

const SESSION_STORAGE_KEY = "AUTH_USER_INFO";

export class AuthApi {
  public static authenticateUser(
    username: string,
    password: string
  ): Promise<IUserInfo> {
    return axios
      .post("authentication/login", { username, password })
      .then(response => {
        sessionStorage.setItem(
          SESSION_STORAGE_KEY,
          JSON.stringify(response.data)
        );
        return response.data;
      });
  }

  public static authenticateToken(token: string): Promise<IFundraiser[]> {
    logger.trace(() => `Authenticating via token ${token}`);
    return axios.post("authentication/token", { token }).then(response => {
      if (response.status === 200) {
        logger.trace(() => "Token Authenticated successfully");
        logger.debug(() => `Auth Response ${JSON.stringify(response.data)}`);
        sessionStorage.setItem(
          SESSION_STORAGE_KEY,
          JSON.stringify(response.data.userInfo)
        );
        return response.data.groups.map((g: any) => {
          return {
            id: g.id,
            groupName: g.groupName,
            phoneNumber: g.phoneNumber,
            endDate: new Date(g.endDate),
            address: {
              addressLine1: g.address.street,
              addressLine2: g.address.street2,
              city: g.address.city,
              state: g.address.state,
              postalCode: g.address.postalCode
            }
          };
        });
      } else if (response.status === 401) {
        throw new Error("Not Authorized");
      }
    });
  }

  public static getUserInfo(): IUserInfo | null {
    const userInfo = sessionStorage.getItem(SESSION_STORAGE_KEY);
    if (!userInfo) {
      return null;
    }

    return JSON.parse(userInfo);
  }

  public static requestLoginByEmail(email: string) {
    logger.trace(() => `Requesting a login token for ${email}`);
    return axios
      .post("authentication/ForgotPassword", { email })
      .then(response => {
        if (response.status === 200) {
        }
      })
      .catch(error => {
        // Do something with the error message
      });
  }

  public static logoutUser(): Promise<any> {
    return axios
      .post("authentication/logout")
      .then(_ => sessionStorage.removeItem(SESSION_STORAGE_KEY));
  }
}
