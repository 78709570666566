import * as React from "react";
import { Container } from "reactstrap";

import { IGiftCard, IIncentiveSelection } from "../../../models";
import { IncentiveSelection } from "./IncentiveSelection";

interface IGiftCardOnlyIncentiveSelectionProperties {
  giftCards: IGiftCard[];
  selectedGiftCard: number | null;
  onSubmit(selections: IIncentiveSelection): void;
}

export const GiftCardOnlyIncentiveSelection: React.SFC<
  IGiftCardOnlyIncentiveSelectionProperties
> = ({
  giftCards,
  onSubmit,
  selectedGiftCard
}: IGiftCardOnlyIncentiveSelectionProperties) => (
  <Container>
    <h2>Select Incentive</h2>
    <p>
      Thank you for booking your sale! As a reward for being a Preferred member
      you get a $25 gift card to one of the merchants below.
    </p>
    <IncentiveSelection
      giftCards={giftCards}
      showProducts={false}
      selectedGiftCard={selectedGiftCard}
      onSubmit={onSubmit}
    />
  </Container>
);
