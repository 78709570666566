import * as React from "react";

import { Link } from "react-router-dom";
import {
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button,
  Table,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
import { Typeahead, TypeaheadProps } from "react-bootstrap-typeahead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DeepClone } from "../../utilities/ObjectUtilities";

import Currency from "../Currency";
import { IBrochure, IProduct, ISeller, IOrderItem } from "../../models";
import { stringify } from "query-string";

interface ITypeAheadEntry {
  id: number;
  label: string;
}

interface IOwnProps {
  orderId: number;
  brochures: IBrochure[];
  seller?: ISeller;
  isEditMode: boolean;
  saveSeller(seller: ISeller, done: boolean): void;
}

interface IState {
  seller: ISeller;
  typeaheadOptions: ITypeAheadEntry[];
  products: IProduct[];
  showPotentialDataLossWarning: boolean;
  invalidItem: boolean;
  firstNameInvalid: boolean;
  lastNameInvalid: boolean;
  isEditMode: boolean;
  orderItemsInvalid: boolean;
  error?: string;
  newItem: IOrderItem;
}

class SellerEditor extends React.Component<IOwnProps, IState> {
  private blankSeller: ISeller = {
    sellerId: 0,
    firstName: "",
    lastName: "",
    gradeId: 1,
    teacher: "",
    orderItems: [],
    totalSold: 0,
    totalFree: 0,
  };

  private typehead: any;

  public state: IState = {
    seller: Object.assign({}, this.blankSeller),
    newItem: {
      productId: 0,
      quantity: 0,
      productCode: "",
      sellerId: 0,
    },
    typeaheadOptions: new Array<ITypeAheadEntry>(),
    products: new Array<IProduct>(),
    showPotentialDataLossWarning: false,
    invalidItem: false,
    firstNameInvalid: false,
    lastNameInvalid: false,
    isEditMode: false,
    orderItemsInvalid: false,
  };

  constructor(props: IOwnProps, context: any) {
    super(props, context);

    this.addItem = this.addItem.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.flattenProductsFromBrochres =
      this.flattenProductsFromBrochres.bind(this);
    this.formatBrochuresForTypeahead =
      this.formatBrochuresForTypeahead.bind(this);
    this.getLabelForProduct = this.getLabelForProduct.bind(this);
    this.saveAndAddSeller = this.saveAndAddSeller.bind(this);
    this.saveSeller = this.saveSeller.bind(this);
    this.updateSelectedProduct = this.updateSelectedProduct.bind(this);
    this.updateSellerInfo = this.updateSellerInfo.bind(this);
    this.validateSeller = this.validateSeller.bind(this);
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      typeaheadOptions: this.formatBrochuresForTypeahead(this.props.brochures),
      products: this.flattenProductsFromBrochres(this.props.brochures),
    });

    if (this.props.seller) {
      this.setState({ seller: this.props.seller });
    }

    this.setState({ isEditMode: this.props.isEditMode });
  }

  componentDidUpdate(prevProps: IOwnProps) {
    if (prevProps.brochures !== this.props.brochures) {
      this.setState({
        typeaheadOptions: this.formatBrochuresForTypeahead(
          this.props.brochures
        ),
        products: this.flattenProductsFromBrochres(this.props.brochures),
      });
    }

    if (
      this.props.seller !== undefined &&
      prevProps.seller !== this.props.seller
    ) {
      this.setState({ seller: DeepClone(this.props.seller) });
    }
  }

  private flattenProductsFromBrochres(brochures: IBrochure[]): IProduct[] {
    return brochures.reduce(
      (acc, val) => acc.concat(val.products),
      new Array<IProduct>()
    );
  }

  private formatBrochuresForTypeahead(
    brochures: IBrochure[]
  ): ITypeAheadEntry[] {
    return brochures
      .reduce((acc, b) => acc.concat(b.products), new Array<IProduct>())
      .map((p) => {
        return {
          id: p.productId,
          label: p.productCode + " - " + p.productName,
        };
      });
  }

  private validateSeller() {
    const newState = {
      firstNameInvalid: false,
      lastNameInvalid: false,
      orderItemsInvalid: false,
    };

    if (
      !this.state.seller.firstName ||
      this.state.seller.firstName.trim().length === 0
    ) {
      newState.firstNameInvalid = true;
    }

    if (
      !this.state.seller.lastName ||
      this.state.seller.lastName.trim().length === 0
    ) {
      newState.lastNameInvalid = true;
    }

    if (this.state.seller.orderItems.length === 0) {
      newState.orderItemsInvalid = true;
    }

    if (
      this.state.firstNameInvalid !== newState.firstNameInvalid ||
      this.state.lastNameInvalid !== newState.lastNameInvalid ||
      this.state.orderItemsInvalid !== newState.orderItemsInvalid
    ) {
      this.setState(newState);
    }

    return (
      !newState.firstNameInvalid &&
      !newState.lastNameInvalid &&
      !newState.orderItemsInvalid
    );
  }

  private saveAndAddSeller() {
    if (!this.validateSeller()) {
      return;
    }

    this.props.saveSeller(this.state.seller, false);
    this.setState({ seller: Object.assign({}, this.blankSeller) });
  }

  private saveSeller(force = false) {
    if (!force) {
      if (
        this.typehead.getInstance().getInput().value ||
        this.state.newItem.quantity
      ) {
        this.setState({ showPotentialDataLossWarning: true });
        return;
      }
    }

    if (!this.validateSeller()) {
      return;
    }

    this.props.saveSeller(this.state.seller, true);
  }

  private addItem(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    let item = this.state.seller.orderItems.find(
      (i) => i.productId === this.state.newItem.productId
    );
    if (!item) {
      const product = this.state.products.find(
        (p) => p.productId === this.state.newItem.productId
      );
      if (!product) {
        this.setState({ invalidItem: true });
        return;
      }

      item = {
        sellerId: this.state.seller.sellerId,
        productId: this.state.newItem.productId,
        quantity: 0,
        productCode: product.productCode,
      };
    }

    item.quantity += this.state.newItem.quantity;

    const seller = DeepClone(this.state.seller);
    seller.orderItems = [
      item,
      ...this.state.seller.orderItems.filter(
        (i) => item && i.productId !== item.productId
      ),
    ];
    this.setState(
      {
        seller,
        newItem: { quantity: 0 } as IOrderItem,
      },
      () => this.typehead.getInstance().clear()
    );
    this.typehead.getInstance().focus();
  }

  private updateSellerInfo(event: React.FormEvent<HTMLInputElement>) {
    const { name, value } = event.currentTarget;
    if (name === "quantity") {
      const newItem = Object.assign({}, this.state.newItem);
      newItem.quantity = parseInt(value, 10);
      this.setState({ newItem });
    } else {
      const seller = Object.assign({}, this.state.seller);
      seller[name] = value;
      this.setState({ seller });
    }
  }

  private updateSelectedProduct(product: ITypeAheadEntry[]) {
    const newItem = Object.assign({}, this.state.newItem);
    newItem.productId = product[0] ? product[0].id : 0;

    if (this.state.invalidItem && newItem.productId) {
      this.setState({ invalidItem: false });
    }

    if (!newItem.productId) {
      this.setState({ invalidItem: true });
    }

    this.setState({ newItem });
  }

  private getLabelForProduct(productId: number) {
    const typeaheadOption = this.state.typeaheadOptions.find(
      (option) => option.id === productId
    );

    return typeaheadOption ? typeaheadOption.label : "";
  }

  private deleteItem(productId: number) {
    const newSeller = DeepClone(this.state.seller);
    newSeller.orderItems = newSeller.orderItems.filter(
      (i) => i.productId !== productId
    );
    this.setState({ seller: newSeller });
  }

  render() {
    return (
      <Container>
        <Modal size="lg" isOpen={this.state.showPotentialDataLossWarning}>
          <ModalHeader>Unsaved Chagnes</ModalHeader>
          <ModalBody>
            <p>
              You currently have an item entered but not added to the sellers
              order. If you continue, this item will be lost. Are you sure you
              want to save the current seller without adding this item to their
              order?
            </p>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={() => this.saveSeller(true)}>
              Save Seller Without Adding Item
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                this.setState({ showPotentialDataLossWarning: false });
              }}
            >
              Add the item and then save the seller
            </Button>
          </ModalFooter>
        </Modal>
        <Form>
          <Row>
            <Col sm={3}>
              <h3>{this.state.isEditMode ? "Edit Seller" : "Add Seller"}</h3>
            </Col>
            <Col className="text-right">
              <Button tag={Link} to={`/order/${this.props.orderId}/sellers`}>
                <FontAwesomeIcon icon="arrow-left" /> Back To Sellers List
              </Button>
              &nbsp;
              {!this.state.isEditMode && (
                <Button color="primary" onClick={this.saveAndAddSeller}>
                  Save and Add New Seller
                </Button>
              )}
              &nbsp;
              <Button
                color={this.state.isEditMode ? "primary" : "secondary"}
                onClick={() => this.saveSeller(false)}
              >
                Save Seller {!this.state.isEditMode && "and Finish"}
              </Button>
            </Col>
          </Row>
          <FormGroup>
            <Label for="firstName">First Name</Label>
            <Input
              type="text"
              name="firstName"
              value={this.state.seller.firstName}
              onChange={this.updateSellerInfo}
              invalid={this.state.firstNameInvalid}
            />
            <FormFeedback>First name is required.</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="lastName">Last Name</Label>
            <Input
              type="text"
              name="lastName"
              value={this.state.seller.lastName}
              onChange={this.updateSellerInfo}
              invalid={this.state.lastNameInvalid}
            />
            <FormFeedback>Last name is required.</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="gradeId">Grade</Label>
            <Input
              type="select"
              name="gradeId"
              value={this.state.seller.gradeId}
              onChange={this.updateSellerInfo}
            >
              <option value="1">None</option>
              <option value="2">Pre-K</option>
              <option value="3">K</option>
              <option value="4">1st</option>
              <option value="5">2nd</option>
              <option value="6">3rd</option>
              <option value="7">4th</option>
              <option value="8">5th</option>
              <option value="9">6th</option>
              <option value="10">7th</option>
              <option value="11">8th</option>
              <option value="12">9th</option>
              <option value="13">10th</option>
              <option value="14">11th</option>
              <option value="15">12th</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="teacher">Teacher</Label>
            <Input
              type="text"
              name="teacher"
              value={this.state.seller.teacher}
              onChange={this.updateSellerInfo}
            />
          </FormGroup>
        </Form>
        <Row>
          <Col md="9">
            <h3>Items Sold</h3>
            {this.state.orderItemsInvalid && (
              <FormFeedback style={{ display: "block" }}>
                At least one item is required.
              </FormFeedback>
            )}
          </Col>
          <Col md="3" className="align-self-end">
            {this.state.seller.orderItems.length > 0 && (
              <SellerSummary
                orderItems={this.state.seller.orderItems}
                products={this.state.products}
              />
            )}
          </Col>
        </Row>
        <Form onSubmit={this.addItem}>
          <Table bordered hover size="md">
            <thead>
              <tr>
                <th>Item Code</th>
                <th>Quantity</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <FormGroup>
                    <Typeahead
                      id="product-search"
                      multiple={false}
                      options={this.state.typeaheadOptions}
                      filterBy={["label"]}
                      placeholder="Product"
                      selectHintOnEnter
                      inputProps={{
                        className: this.state.invalidItem ? "is-invalid" : "",
                      }}
                      ref={(typeahead: any) => (this.typehead = typeahead)}
                      onChange={this.updateSelectedProduct}
                    />
                    {this.state.invalidItem && (
                      <FormFeedback style={{ display: "block" }}>
                        Item not found!
                      </FormFeedback>
                    )}
                  </FormGroup>
                </td>
                <td>
                  <Input
                    type="number"
                    name="quantity"
                    value={this.state.newItem.quantity}
                    onChange={this.updateSellerInfo}
                  />
                </td>
                <td>
                  <Button>
                    <FontAwesomeIcon icon="plus" />
                  </Button>
                </td>
              </tr>
              {this.state.seller.orderItems.map((item) => (
                <tr key={item.productId}>
                  <td>{this.getLabelForProduct(item.productId)}</td>
                  <td>{item.quantity}</td>
                  <td>
                    <Button
                      color="danger"
                      size="sm"
                      onClick={() => this.deleteItem(item.productId)}
                    >
                      <FontAwesomeIcon icon="trash-alt" />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Form>
      </Container>
    );
  }
}

const SellerSummary = ({
  orderItems,
  products,
}: {
  orderItems: IOrderItem[];
  products: IProduct[];
}) => (
  <div className="text-right">
    <div>
      &nbsp;
      <span>
        {orderItems.reduce((acc, i) => {
          return acc + i.quantity;
        }, 0)}
      </span>
      &nbsp;
      <strong>Items Sold</strong>
    </div>
    <div>
      <Currency
        value={orderItems.reduce((acc, i) => {
          const product = products.find((p) => p.productId === i.productId);
          return acc + i.quantity * (product ? product.price : 0);
        }, 0)}
      />
      &nbsp;
      <strong>Dollars Sold</strong>
    </div>
  </div>
);

export default SellerEditor;
