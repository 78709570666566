import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./LoadingSpinner.css";

const LoadingSpinner = () => (
  <div className="loading_spinner__container">
    <FontAwesomeIcon icon="spinner" spin size="3x" />
  </div>
);

export default LoadingSpinner;
