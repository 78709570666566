import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Container,
  Label,
  Input,
  FormGroup,
  Col,
  Card,
  CardText,
  CardHeader,
  CardBody
} from "reactstrap";

interface IOwnProps {
  orderId: number;
}

interface IState {
  confirmed: boolean;
}

class DeliveryInfoPage extends React.Component<IOwnProps, IState> {
  public state: IState = {
    confirmed: false
  };

  constructor(props: IOwnProps, context: any) {
    super(props, context);

    this.handleChange = this.handleChange.bind(this);
  }

  private handleChange(event: React.FormEvent<HTMLInputElement>) {
    this.setState({ confirmed: event.currentTarget.checked });
  }

  render() {
    return (
      <Container>
        <br />
        <Col tag="div" md={{ size: 8, offset: 2 }}>
          <Card>
            <CardHeader tag="h1" className="text-center">
              Important Delivery Information!
            </CardHeader>
            <CardBody>
              <CardText tag="div">
                <p className="text-center">
                  <strong>
                    Shipping to your place of business is provided at no cost to
                    you: however, your designated freight carrier will charge
                    you (the customer) a minimum of $100.00 for inside delivery,
                    if requested.
                  </strong>
                </p>
                <p>In order to avoid this charge, please follow these steps:</p>
                <ol>
                  <li>
                    Arrange a group of volunteers to carry the boxes into your
                    facility on the delivery day we provide you.
                  </li>
                  <li>
                    Call your local Freight Company Service Center with the
                    number we provide you before 7:00am on your delivery day.
                  </li>
                  <li>
                    Ask for an estimate arrival time. They will provide you with
                    a 3 hour window for your delivery, however, in some cases
                    delivery may be made outside of the 3 hour window.
                  </li>
                  <li>
                    Call the volunteers you have previously organized and let
                    them know what time they will need to be there to help bring
                    the delivery inside your facility.
                  </li>
                </ol>
                <p className="text-center">
                  <strong>
                    If no volunteers are available, you may request inside
                    delivery, but please be aware that your freight carrier will
                    invoice you/your organization for an inside delivery fee
                    between $100.00 - $150.00.
                  </strong>
                </p>

                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" onChange={this.handleChange} />I have
                    read and unsderstand that I will be responsible for paying
                    the inside delivery fee if I request inside delivery from
                    the delivery driver.
                  </Label>
                </FormGroup>
                <div className="text-center">
                  <Button
                    color="primary"
                    tag={Link}
                    to={`/order/${this.props.orderId}/profit`}
                    disabled={!this.state.confirmed}
                  >
                    Continue
                  </Button>
                </div>
              </CardText>
            </CardBody>
          </Card>
        </Col>
      </Container>
    );
  }
}

export default DeliveryInfoPage;
