import * as React from "react";
import { Link } from "react-router-dom";
import { Col, ListGroup, ListGroupItem, Button } from "reactstrap";
import { IFundraiser } from "../../models";

interface IOwnProps {
  availableFundraisers: IFundraiser[];
  selectGroup(id: number): void;
}

interface IState {
  selectedGroup: number;
}

class OrderSelectionPage extends React.Component<IOwnProps, IState> {
  public state = {
    selectedGroup: 0
  };

  constructor(props: IOwnProps, context: any) {
    super(props, context);
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  private handleChange(event: React.FormEvent<HTMLInputElement>): void {
    this.setState({ selectedGroup: Number(event.currentTarget.value) });
  }

  public onSubmit() {
    this.props.selectGroup(this.state.selectedGroup);
  }

  render() {
    return (
      <Col lg={{ size: 4, offset: 4 }}>
        <h1>Select Your Group</h1>
        <p>
          It looks like you are raising funds for multiple groups, which group's
          order would you like to enter?
        </p>
        <ListGroup>
          {this.props.availableFundraisers &&
            this.props.availableFundraisers.map(f => (
              <ListGroupItem key={f.id}>
                <h4>{f.groupName}</h4>
                <strong>Phone:</strong> {f.phoneNumber}
                <br />
                <strong>Ending:</strong>{" "}
                {new Intl.DateTimeFormat("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "2-digit"
                }).format(f.endDate)}
                <br />
                <strong>Location:</strong>
                <br />
                <p style={{ paddingLeft: "25px" }}>
                  {f.address.addressLine1}
                  <br />
                  {f.address.city}, {f.address.state} {f.address.postalCode}
                </p>
                <Button
                  outline
                  color="primary"
                  tag={Link}
                  to={`/order/${f.id}/`}
                >
                  Enter Order
                </Button>
              </ListGroupItem>
            ))}
        </ListGroup>
      </Col>
    );
  }
}

export default OrderSelectionPage;
