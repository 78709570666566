import * as React from "react";
import { Button, Col, Input, Label, Table } from "reactstrap";
import {
  IIncentiveProductSelection,
  IIncentiveSelection,
  IProduct
} from "../../../models";

interface IProductIncentiveSelectionFormProperties {
  products: IProduct[];
  selectedProducts: IIncentiveProductSelection[];
  onSubmit(selected: IIncentiveSelection): void;
}

interface IProductIncentiveSelectionFormState {
  selectedProducts: IIncentiveProductSelection[];
  numberSelected: number;
}

export class ProductIncentiveSelectionForm extends React.Component<
  IProductIncentiveSelectionFormProperties,
  IProductIncentiveSelectionFormState
> {
  public static getDerivedStateFromProps(
    nextProps: IProductIncentiveSelectionFormProperties,
    prevState: IProductIncentiveSelectionFormState
  ) {
    if (
      prevState.selectedProducts.length === 0 &&
      nextProps.products.length > 0
    ) {
      const selectedProducts = nextProps.products.map(p => {
        const previouslySelected = nextProps.selectedProducts.find(
          ps => ps.productCode === p.productCode
        ) || { quantity: 0 };

        return {
          productName: p.productName,
          productCode: p.productCode,
          quantity: previouslySelected.quantity
        };
      });

      return {
        selectedProducts,
        numberSelected: selectedProducts.reduce((acc, p) => acc + p.quantity, 0)
      };
    }

    return null;
  }

  public state = {
    selectedProducts: [],
    numberSelected: 0
  };

  public handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const name = event.currentTarget.name;
    const value = parseInt(event.currentTarget.value, 10) || 0;
    const selectedProducts: IIncentiveProductSelection[] = JSON.parse(
      JSON.stringify(this.state.selectedProducts)
    );
    const updatedProduct = selectedProducts.find(p => p.productCode === name);
    if (!updatedProduct) {
      throw Error(
        "Unable to find the product we are trying to select, something unexpected happened"
      );
    }

    updatedProduct.quantity = value;
    const numberSelected = selectedProducts.reduce(
      (acc, s) => acc + s.quantity,
      0
    );

    this.setState({
      selectedProducts,
      numberSelected
    });
  };

  public onSubmit = () => {
    const selections: IIncentiveSelection = {
      selectedGiftCard: null,
      selectedProducts: this.state.selectedProducts
        .filter((p: IIncentiveProductSelection) => p.quantity > 0)
        .map((p: IIncentiveProductSelection) => {
          const product: IIncentiveProductSelection = {
            productCode: p.productCode,
            quantity: p.quantity
          };
          return product;
        })
    };
    this.props.onSubmit(selections);
  };

  public render() {
    return (
      <React.Fragment>
        <p>Please choose your free items below.</p>
        <Col lg={{ size: 6, offset: 3 }}>
          <Table size="sm" bordered={true}>
            <thead>
              <tr>
                <th>Flavor</th>
                <th style={{ width: "10%" }}>Quantity</th>
              </tr>
            </thead>
            <tbody>
              {this.state.selectedProducts.map(
                (product: IIncentiveProductSelection) => (
                  <tr key={product.productCode}>
                    <td>
                      <Label for={product.productCode} sm={9}>
                        {product.productName}
                      </Label>
                    </td>
                    <td>
                      <Input
                        type="number"
                        name={product.productCode}
                        value={product.quantity}
                        min="0"
                        onChange={this.handleChange}
                      />
                    </td>
                  </tr>
                )
              )}
            </tbody>
            <tfoot>
              <tr>
                <td>Total</td>
                <td>{this.state.numberSelected}</td>
              </tr>
            </tfoot>
          </Table>
          <Button
            color="primary"
            disabled={this.state.numberSelected !== 10}
            onClick={this.onSubmit}
          >
            Continue
          </Button>
        </Col>
      </React.Fragment>
    );
  }
}
