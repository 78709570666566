import * as React from "react";
import { Alert } from "reactstrap";

// TODO: Properly type this
const ValidationErrorAlert = ({ errors }: { errors: string[] }) => {
  return (
    <Alert color="danger">
      <strong>OOOPS!</strong>
      <ul>
        {errors.map(errorMessage => (
          <li key={errorMessage}>{errorMessage}</li>
        ))}
      </ul>
    </Alert>
  );
};

export default ValidationErrorAlert;
