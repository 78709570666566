export enum PaymentType {
  CreditCard = 1,
  Check = 2
}

export interface IAuthorizationRequest {
  paymentType: PaymentType;
  nameOnCard: string;
  creditCardNumber: string;
  expirationMonth: number;
  expirationYear: number;
  cvv2: number;
  email: string;
  street: string;
  city: string;
  state: string;
  postalCode: string;
}

export interface IAuthorizationResult {
  isApproved: boolean;
  response: string;
  avsResponse: string;
  cvv2Response: string;
  transactionId: string;
}
