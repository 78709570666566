import axios from "axios";
import { getLogger } from "../utilities/LogConfig";
import { IBranding } from "../models";

const BRANDING_LOCAL_STORAGE_KEY = "SITE_BRANDING_INFO";
const logger = getLogger("BrandingApi");

export class BrandingApi {
  public static getBranding(): Promise<IBranding> {
    return new Promise<IBranding>((resolve, reject) => {
      const branding = sessionStorage.getItem(BRANDING_LOCAL_STORAGE_KEY);
      if (branding) {
        try {
          logger.info("Returned cached branding");
          return resolve(JSON.parse(branding));
        } catch (e) {
          logger.warn(() => "Stored branding is invalid");
        }
      }

      logger.info("Retrieving branding from server");
      return axios
        .get("branding")
        .then(response => {
          logger.info(`Got branding for ${response.data.brandingName}`);
          logger.info(
            `Saving branding to sessionStorage key ${BRANDING_LOCAL_STORAGE_KEY}`
          );
          sessionStorage.setItem(
            BRANDING_LOCAL_STORAGE_KEY,
            JSON.stringify(response.data)
          );
          resolve(response.data);
        })
        .catch(reason => {
          logger.info(`Error getting branding from server: ${reason}`);
          return reject(reason);
        });
    });
  }
}
