import fontawesome from "@fortawesome/fontawesome";
import faPlus from "@fortawesome/fontawesome-free-solid/faPlus";
import faCheck from "@fortawesome/fontawesome-free-solid/faCheck";
import faTrashAlt from "@fortawesome/fontawesome-free-solid/faTrashAlt";
import faEdit from "@fortawesome/fontawesome-free-solid/faEdit";
import faSpinner from "@fortawesome/fontawesome-free-solid/faSpinner";
import faArrowLeft from "@fortawesome/fontawesome-free-solid/faArrowLeft";
import { faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons/faFileInvoiceDollar";
import faListOl from "@fortawesome/fontawesome-free-solid/faListOl";

export default function buildFontawesomeLibrary() {
  fontawesome.library.add(
    faPlus,
    faCheck,
    faTrashAlt,
    faEdit,
    faSpinner,
    faArrowLeft,
    faFileInvoiceDollar,
    faListOl
  );
}
