import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label
} from "reactstrap";

import { Link } from "react-router-dom";
// import { componentLogger } from "src/logger";

interface IOwnProps {
  loginUser(username: string, password: string): void;
}

interface IState {
  username: string;
  password: string;
  errorMessage: string;
}

export class LoginPage extends React.Component<
  IOwnProps & RouteComponentProps<{ token?: string }>,
  IState
> {
  public state = {} as IState;

  constructor(
    props: IOwnProps & RouteComponentProps<{ token?: string }>,
    context: any
  ) {
    super(props, context);
    this.handleChange = this.handleChange.bind(this);
    this.login = this.login.bind(this);
  }

  public login(event: React.FormEvent): void {
    event.preventDefault();
    this.props.loginUser(this.state.username, this.state.password);
  }

  public handleChange(event: React.FormEvent<HTMLInputElement>): void {
    const { name, value } = event.currentTarget;
    const newState = { ...this.state, [name]: value };
    this.setState(newState);
  }

  public render() {
    return (
      <Container>
        <Col md="6" className="ml-auto mr-auto">
          <h1>Login</h1>

          <Form onSubmit={this.login}>
            <FormGroup>
              <Label for="username">Username</Label>
              <Input
                type="text"
                name="username"
                defaultValue={this.state.username}
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="password">Password</Label>
              <Input
                type="password"
                name="password"
                defaultValue={this.state.password}
                onChange={this.handleChange}
              />
            </FormGroup>
            <Button color="primary">Login</Button>
            &nbsp;{" "}
            <Link to="/forgot-password">
              Don't Know Your Login? Click Here!
            </Link>
          </Form>
        </Col>
      </Container>
    );
  }
}

export default withRouter(LoginPage);
