import * as React from "react";
import { Input } from "reactstrap";
import { states } from "./states";

export interface IState {
  name: string;
  abbreviation: string;
}

interface IOwnProps {
  selected: string;
  onChange(selected: string): void;
}

export default class StateSelector extends React.Component<IOwnProps> {
  constructor(props: IOwnProps, context: any) {
    super(props, context);
    this.handleChange = this.handleChange.bind(this);
  }

  public render() {
    return (
      <Input
        type="select"
        defaultValue={this.props.selected}
        onChange={this.handleChange}
      >
        {states.map((state: IState) => (
          <option key={state.abbreviation} value={state.abbreviation}>
            {state.name}
          </option>
        ))}
      </Input>
    );
  }

  private handleChange(event: React.FormEvent<HTMLInputElement>): void {
    this.props.onChange(event.currentTarget.value);
  }
}
