import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {
  Button,
  Container,
  Label,
  Input,
  FormGroup,
  Table,
  Col,
  Row
} from "reactstrap";
import { getLogger } from "../../utilities/LogConfig";

import Currency from "../Currency";
import { OrderApi } from "../../api";
import {
  IProfitOption,
  IProfitOptionType,
  IProfitDetail
} from "../../models/profit";

const logger = getLogger("ProfitPage");
const wording = new Map();
wording.set(1, {
  label: <strong>By Seller</strong>,
  description: (
    <React.Fragment>
      Your order will come to you sorted by seller in bags/boxes ready to
      distribute. <strong>Minimal work required by you</strong>.
    </React.Fragment>
  )
});

wording.set(2, {
  label: <strong>Bulk</strong>,
  description: (
    <React.Fragment>
      Your order will ship in cases by flavor. You must sort your sellers orders
      using their order form and then distribute. This will require you to
      package all orders & you may need volunteers.{" "}
      <strong>Does not include extra bags/boxes</strong>.
    </React.Fragment>
  )
});

interface IOwnProps {
  orderId: number;
  canPayByCreditCard: boolean;
}

interface IState {
  profitOptions: IProfitOption[];
  selectedProfit: IProfitOptionType;
}

export class ProfitPage extends React.Component<
  IOwnProps & RouteComponentProps,
  IState
> {
  public state: IState = {
    profitOptions: new Array<IProfitOption>(),
    selectedProfit: IProfitOptionType.NotSelected
  };

  constructor(props: IOwnProps & RouteComponentProps, context: any) {
    super(props, context);

    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async componentDidMount() {
    const profitOptions = await OrderApi.getProfitOptions(this.props.orderId);
    this.setState({ profitOptions });
  }

  private handleChange(selectedProfit: IProfitOptionType) {
    this.setState({ selectedProfit });
  }

  private async onSubmit() {
    await OrderApi.saveProfitOptionSelection(
      this.props.orderId,
      this.state.selectedProfit
    ).then(_ => {
      logger.trace(
        () => `Profit Option Selection made ${this.state.selectedProfit}`
      );
      logger.debug(
        () =>
          `Site does${
            this.props.canPayByCreditCard ? " " : " not "
          }support payment by credit card`
      );
      if (this.props.canPayByCreditCard) {
        logger.trace(() => "Redirecting to the payment page");
        this.props.history.push(`/order/${this.props.orderId}/payment`);
      } else {
        logger.trace(() => "Redirecting to the success page");
        this.props.history.push(`/order/${this.props.orderId}/success`);
      }
    });
  }

  render() {
    return (
      <Container>
        <h2>Select Your Profit</h2>
        {this.state.profitOptions.map(o => (
          <ProfitOptionView
            profitOption={o}
            key={o.type}
            onChange={this.handleChange}
          />
        ))}
        <div className="text-center">
          <Button
            color="primary"
            onClick={this.onSubmit}
            disabled={!this.state.selectedProfit}
          >
            Finalize Order
          </Button>
        </div>
      </Container>
    );
  }
}

export default withRouter(ProfitPage);

const ProfitOptionView = ({
  profitOption,
  onChange
}: {
  profitOption: IProfitOption;
  onChange(selectedProfit: IProfitOptionType): void;
}) => {
  const ProfitRows = ({ profitDetail }: { profitDetail: IProfitDetail[] }) => {
    const rows = [];
    for (let i = 0; i < profitDetail.length; i = i + 2) {
      rows.push(
        <tr key={profitDetail[i].productCode}>
          <td>{profitDetail[i].productDescription}</td>
          <td>
            <Currency value={profitDetail[i].profitPerItem} />
          </td>
          <td>
            {profitDetail.length > i + 1 &&
              profitDetail[i + 1].productDescription}
          </td>
          <td>
            {profitDetail.length > i + 1 && (
              <Currency value={profitDetail[i + 1].profitPerItem} />
            )}
          </td>
        </tr>
      );
    }

    return <tbody>{rows}</tbody>;
  };

  return (
    <React.Fragment>
      <Row>
        <Col sm={9}>
          <FormGroup check>
            <Label>
              <Input
                type="radio"
                name="selectedProfit"
                onChange={_ => onChange(profitOption.type)}
              />
              {wording.get(profitOption.type).label}
            </Label>
          </FormGroup>
          <p>{wording.get(profitOption.type).description}</p>
        </Col>
        <Col sm={3}>
          <div>
            <strong>Total Profit</strong>
            <br />
            <span>
              <Currency value={profitOption.totalProfit} />
            </span>
          </div>
        </Col>
      </Row>
      <Table size="sm" bordered>
        <thead>
          <tr>
            <th>Flavor</th>
            <th>Profit Per Item</th>
            <th>Flavor</th>
            <th>ProfitPerItem</th>
          </tr>
        </thead>
        <ProfitRows profitDetail={profitOption.profitDetail} />
      </Table>
    </React.Fragment>
  );
};
