import axios, { AxiosResponse } from "axios";
import {
  IAddress,
  ISeller,
  Order,
  IAvailableIncentives,
  IIncentiveSelection
} from "../models";
import { IProfitOption, IProfitOptionType } from "../models/profit";
import { IInvoice } from "../models/invoice";
import {
  IAuthorizationResult,
  IAuthorizationRequest,
  PaymentType
} from "../models/creditCardAuth";
import { IGroupSearchResult } from "../models/groupSearchResult";

export class OrderApi {
  public static async findOrdersByGroupName(groupName: string): Promise<IGroupSearchResult[]> {
    return axios.get(`groups/${groupName}`).then(response => {
      return response.data;
    });
  }

  public static async getSellerReportByOrderId(orderId: number): Promise<any> {
    const response = await axios.get(`groups/seller-reports/${orderId}`,{
      responseType: 'blob',
    });
    return response.data;
  }

  public static async getOrder(orderId: number): Promise<Order> {
    const response = await axios.get(`order/${orderId}`);
    return response.data;
  }

  public static async getSellers(orderId: number): Promise<ISeller[]> {
    const response = await axios.get(`order/${orderId}/seller`);
    return response.data;
  }

  public static async getSeller(orderId: number, sellerId: number): Promise<ISeller> {
    const response = await axios.get(`order/${orderId}/seller/${sellerId}`);
    return response.data;
  }

  public static async saveSeller(orderId: number, seller: ISeller): Promise<ISeller> {
    if (seller.sellerId === 0) {
      const response = await axios.post(`order/${orderId}/seller`, seller);
      return response.data;
    } else {
      return OrderApi.updateSeller(orderId, seller.sellerId, seller);
    }
  }

  public static async updateSeller(
    orderId: number,
    sellerId: number,
    seller: ISeller
  ): Promise<ISeller> {
    const response = await axios
      .put(`order/${orderId}/seller/${sellerId}`, seller);
    return response.data;
  }

  public static async deleteSeller(
    orderId: number,
    sellerId: number
  ): Promise<boolean> {
    const _ = await axios.delete(`order/${orderId}/seller/${sellerId}`);
    return true;
  }

  public static async saveShippingAddress(
    orderId: number,
    address: IAddress
  ): Promise<IAddress> {
    const response = await axios.post(`order/${orderId}/address`, address);
    return response.data;
  }

  public static async getAvailableIncentives(
    orderId: number
  ): Promise<IAvailableIncentives & IIncentiveSelection> {
    const response = await axios.get(`order/${orderId}/incentives`);
    return response.data;
  }

  public static async saveIncentiveSelections(
    orderId: number,
    selections: IIncentiveSelection
  ): Promise<void> {
    const _ = await axios.post(`order/${orderId}/incentives`, selections);
  }

  public static async getProfitOptions(orderId: number): Promise<IProfitOption[]> {
    const response = await axios.get(`order/${orderId}/profit-option`);
    return response.data;
  }

  public static async saveProfitOptionSelection(
    orderId: number,
    profitOption: IProfitOptionType
  ): Promise<void> {
    const _ = await axios
      .post(`order/${orderId}/profit-option`, { type: profitOption });
  }

  public static async getInvoice(orderId: number): Promise<IInvoice> {
    const response = await axios
      .get(`order/${orderId}/invoice`);
    return response.data;
  }

  public static async authorizeCreditCard(
    orderId: number,
    request: IAuthorizationRequest
  ): Promise<IAuthorizationResult> {
    const response = await axios
      .post(`order/${orderId}/payment`, request);
    return response.data;
  }

  public static async payByCheck(orderId: number): Promise<any> {
    const response = await axios
      .post(`order/${orderId}/payment`, { paymentType: PaymentType.Check });
    return response.data;
  }
}
