import * as React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  FormText,
  Input,
  Row
} from "reactstrap";
import { getLogger } from "../../utilities/LogConfig";
import { AuthApi } from "../../api";
const logger = getLogger("ForgotPasswordPage");

interface IState {
  email: string;
  requestSent: boolean;
}

export default class ForgotPasswordPage extends React.Component<{}, IState> {
  public state = {
    email: "",
    requestSent: false
  };

  constructor(props: {}, context: any) {
    super(props, context);
    this.handleChange = this.handleChange.bind(this);
    this.requestLoginLink = this.requestLoginLink.bind(this);
    this.renderRequestForm = this.renderRequestForm.bind(this);
    this.renderSentMessage = this.renderSentMessage.bind(this);
  }

  public requestLoginLink(event: React.FormEvent) {
    event.preventDefault();
    logger.info(() => `Requesting login link for ${this.state.email}`);
    AuthApi.requestLoginByEmail(this.state.email).then(() => {
      logger.info(() => "Email sent!");
      this.setState({ requestSent: true });
    });
  }

  public handleChange(event: React.FormEvent<HTMLInputElement>) {
    const { name, value } = event.currentTarget;
    const newState = { ...this.state, [name]: value };
    this.setState(newState);
  }

  private renderRequestForm() {
    return (
      <Form onSubmit={this.requestLoginLink}>
        <p>
          <FormText>
            Forgot your password? Enter the email address you used when booking
            your fundraiser below and click Request Login Link and we will send
            you a link to get you started entering your fundraising order.
          </FormText>
        </p>

        <FormGroup>
          <Input
            type="email"
            name="email"
            onChange={this.handleChange}
            value={this.state.email}
            placeholder="Email Address"
          />
        </FormGroup>
        <Button>Request Login Link</Button>
      </Form>
    );
  }

  private renderSentMessage() {
    return (
      <p>
        An email has been sent to {this.state.email}. Check your email for
        further instructions.
      </p>
    );
  }

  public render() {
    return (
      <Container>
        <Row>
          <Col
            md={{
              size: 4,
              offset: 3
            }}
          >
            <Card>
              <CardHeader>Forgot Password</CardHeader>
              <CardBody>
                {this.state.requestSent
                  ? this.renderSentMessage()
                  : this.renderRequestForm()}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
