import React from "react";
import { withRouter, Link, RouteComponentProps } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Alert,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getLogger } from "../../utilities/LogConfig";

import { joinUrl } from "../../utilities/UrlUtilities";
import { CustomerStatus, ISeller } from "../../models";
//import SellerEditor from "./SellerEditor";

import "./SellersSummary.css";
import { BrandingApi } from "../../api";

const logger = getLogger("SellersSummary");

interface IHash {
  [details: number]: string;
}

const GRADE_LOOKUP: IHash = {
  1: "None",
  2: "PreK",
  3: "K",
  4: "1st",
  5: "2nd",
  6: "3rd",
  7: "4th",
  8: "5th",
  9: "6th",
  10: "7th",
  11: "8th",
  12: "9th",
  13: "10th",
  14: "11th",
  15: "12th",
};

interface IOwnProps {
  sellers: ISeller[];
  customerStatus: CustomerStatus;
  hasMetMinimum: boolean;
  deleteSeller(seller: ISeller): void;
}

interface IState {
  confirmDeleteOpen: boolean;
  sellerDeleting: ISeller | null;
}

class SellersSummary extends React.Component<
  IOwnProps & RouteComponentProps,
  IState
> {
  public state: IState = {
    confirmDeleteOpen: false,
    sellerDeleting: null,
  };

  constructor(props: IOwnProps & RouteComponentProps, context: any) {
    super(props, context);
    this.doneAddingSellers = this.doneAddingSellers.bind(this);
    this.editSeller = this.editSeller.bind(this);
    this.deleteSeller = this.deleteSeller.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
    this.getSellerDeletingName = this.getSellerDeletingName.bind(this);
    this.renderSellersList = this.renderSellersList.bind(this);
  }

  private async doneAddingSellers() {
    //if(this.props.customerStatus === CustomerStatus.None) {
    const branding = await BrandingApi.getBranding();
    const nextPage = branding.isDeliveredByFreight ? "delivery-info" : "profit";
    console.log("No incentives offered, redirecting to", `${nextPage}`);
    if (!this.props.hasMetMinimum) {
      console.log("not enough");
    } else {
      this.props.history.push(`${nextPage}`);
    }
    //}
    //this.props.history.push("incentives");
  }

  private editSeller(sellerId: number) {
    this.props.history.push(
      joinUrl(this.props.match.url, `/${sellerId}/edit/`)
    );
  }

  private deleteSeller(seller: ISeller) {
    logger.trace(
      () =>
        `Showing confirm dialog for seller deleting seller ${seller.sellerId}`
    );
    this.setState({
      sellerDeleting: seller,
      confirmDeleteOpen: true,
    });
  }

  private confirmDelete() {
    if (this.state.sellerDeleting !== null) {
      this.props.deleteSeller(this.state.sellerDeleting);
      this.setState({ sellerDeleting: null, confirmDeleteOpen: false });
    }
  }

  private cancelDelete() {
    logger.trace(() => "Canceling pending delete");
    this.setState({
      sellerDeleting: null,
      confirmDeleteOpen: false,
    });
  }

  private getSellerDeletingName(): string {
    if (this.state.sellerDeleting) {
      let name = this.state.sellerDeleting.firstName || "";
      if (this.state.sellerDeleting.lastName) {
        name = name + " " + this.state.sellerDeleting.lastName;
      }

      return name.trim();
    }

    return "";
  }

  private renderSellersList() {
    return (
      <Table
        striped={true}
        bordered={true}
        hover={true}
        className="sellers_list__sellers_table"
      >
        <thead>
          <tr>
            <th>Name</th>
            <th>Grade</th>
            <th># Items Sold</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {this.props.sellers.map((seller) => (
            <tr key={seller.sellerId}>
              <td>
                {seller.firstName} {seller.lastName}
              </td>
              <td>{GRADE_LOOKUP[seller.gradeId]}</td>
              <td>{seller.totalSold}</td>
              <td className="sellers_list__action_column">
                <Button
                  color="danger"
                  size="sm"
                  onClick={() => this.deleteSeller(seller)}
                >
                  <FontAwesomeIcon icon="trash-alt" />
                </Button>
                <Button
                  color="secondary"
                  size="sm"
                  onClick={() => this.editSeller(seller.sellerId)}
                >
                  <FontAwesomeIcon icon="edit" />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }

  render() {
    return (
      <Container>
        <Row>
          <Col>
            <h1>Enter Sellers</h1>
          </Col>
          <Col className="text-right">
            <Button
              tag={Link}
              to={joinUrl(this.props.match.url, "add")}
              color="primary"
            >
              <FontAwesomeIcon icon="plus" /> Add Sellers
            </Button>{" "}
            <Button
              onClick={this.doneAddingSellers}
              disabled={!this.props.hasMetMinimum}
              color="secondary"
            >
              <FontAwesomeIcon icon="check" /> Done Adding Sellers
            </Button>
          </Col>
        </Row>
        {!this.props.hasMetMinimum && (
          <Row>
            <Col>
              <Alert color="warning">
                <p>
                  You have not met the minimum number of 90 items required to
                  continue
                </p>
              </Alert>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <Modal isOpen={this.state.confirmDeleteOpen}>
              <ModalHeader>Confirm Delete</ModalHeader>
              <ModalBody>
                <p>
                  Are you sure you would like to delete{" "}
                  <strong>{this.getSellerDeletingName()}</strong>? This action
                  cannot be undone
                </p>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" onClick={this.confirmDelete}>
                  Delete
                </Button>
                <Button color="secondary" onClick={this.cancelDelete}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
            {this.renderSellersList()}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(SellersSummary);
