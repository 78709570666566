import { LogLevel } from "typescript-logging";
import {
  CategoryProvider,
} from "typescript-logging-category-style";

const provider = process.env.NODE_ENV === "production"
  ? CategoryProvider.createProvider("Production", {
      level: LogLevel.Error
  })
  : CategoryProvider.createProvider("Development", {
      level: LogLevel.Trace
  });

const componentLogger = provider.getCategory("Component");

export { componentLogger };
