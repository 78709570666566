import axios from "axios";
import { createBrowserHistory } from "history";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Router } from "react-router";

import "./index.css";

import App from "./App";
import buildFontawesomeLibrary from "./buildFontawesomeLibrary";

buildFontawesomeLibrary();
export const history = createBrowserHistory();

let isLoading = false;

axios.defaults.baseURL = "/api/";

// if (process.env.NODE_ENV === "development") {
//   history.listen((location, action) => {
//     console.log(
//       `The current URL is ${location.pathname}${location.search}${
//         location.hash
//       }`
//     );
//     console.log(
//       `The last navigation action was ${action}`,
//       JSON.stringify(history, null, 2)
//     );
//   });
// }

ReactDOM.render(
  <Router history={history}>
    <App />
  </Router>,
  document.getElementById("root")
);
