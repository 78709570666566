import * as React from "react";
import {
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Collapse
} from "reactstrap";

interface IOwnProps {
  brandName?: string;
  groupName?: string;
  isAuthenticated: boolean;
  logout(): void;
}

export class Header extends React.Component<IOwnProps> {
  state = {
    isOpen: false
  };

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  logout = () => {
    this.props.logout();
  };

  render() {
    return (
      <Navbar color="light" light expand="md">
        {this.props.brandName && (
          <NavbarBrand href="/">{this.props.brandName}</NavbarBrand>
        )}
        <NavbarToggler onClick={this.toggle} />
        <Nav className="justify-content-center w-100">
          {this.props.groupName && (
            <NavItem>
              <h4>Entering Order For {this.props.groupName}</h4>
            </NavItem>
          )}
        </Nav>
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ml-auto" navbar>
            {/*
                        <NavItem>
                            <NavLink href="/help">Help</NavLink>
                        </NavItem>
                        */}
            <NavItem>
              {this.props.isAuthenticated && (
                <NavLink href="#" onClick={this.logout}>
                  Logout
                </NavLink>
              )}
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}

export default Header;
